
import Lenis from '@studio-freight/lenis'
import Swiper from 'swiper/bundle';
import { Navigation, Pagination} from 'swiper/modules';

import select2 from 'select2';
select2();
/**************** LENIS *************************/
const lenis = new Lenis({
  duration: 1.2,
  easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
  direction: "vertical",
  gestureDirection: "vertical",
  smooth: true,
  smoothTouch: false,
  touchMultiplier: 2,
});

window.lenis = lenis;

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

var modal = document.getElementById('navbarNavOffcanvas');
modal.addEventListener('hide.bs.offcanvas', function () {
 console.log('startlenis');
 lenis.start()
});
modal.addEventListener('show.bs.offcanvas', function () {
 console.log('stoplenis');
 lenis.stop()
   
 
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();
    lenis.scrollTo(this.getAttribute('href'))
  });
})

/**************** Swiper *************************/
if ( jQuery('.portfolio-swiper-container').length > 0) {
  var sliderArray = [];
  jQuery('.portfolio-swiper-container .swiper-slide').each(function (index, element) {
    sliderArray.push(jQuery(this).attr('data-id'));
    
  });
  
  var menu = sliderArray
  new Swiper ('.portfolio-swiper-container', {
    modules: [Navigation, Pagination],
      // If we need pagination
      pagination: {
        el: '.portfolio-swiper-pagination',
        clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (menu[index]) + '</span>';
          },
      },
      direction: 'horizontal',
      speed: 1,
      slidesPerView: 1,
      spaceBetween: 50,
        centeredSlides: true,
      slideToClickedSlide: true,
      allowTouchMove: true,
      loop: true,
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }; 


 
  jQuery( '.gform_wrapper' ).each( function () {
    var form = jQuery( this );
    jQuery( 'input', form ).each( function () {
      var input = jQuery( this );
      if ( input.attr( 'aria-required' ) == 'true' ) {
        var placeholder = input.attr( 'placeholder' );
        var placeholder = placeholder + ' *';
        input.attr( 'placeholder', placeholder );
      }
    } );
    jQuery( 'select', form ).each( function () {
      var select = jQuery( this );
      if ( select.attr( 'aria-required' ) == 'true' ) {
        var option = jQuery( 'option', select ).first();
        var placeholder = option.text() + ' *';
        option.text( placeholder );
      }
    } );
   
    jQuery( 'select', form ).select2({
      dropdownParent: '#enquire',
    }
      
    );
    jQuery( 'select-disabled', form ).select2();
  } );




  jQuery('.footer-content .enquire-btn').closest('li').remove();


  let firstSection = document.querySelector('section:first-of-type');
  //console.log(firstSection.getAttribute("data-color"));
  //console.log(firstSection.getAttribute("data-nav"));
  let scolor = firstSection.getAttribute("data-color");
  let scolorNav = firstSection.getAttribute("data-nav");
      
  let spageHeader = document.querySelector('.navbar');
   spageHeader.style.backgroundColor = scolor;
   spageHeader.setAttribute("data-nav", scolorNav);
  
  const pageHeader = document.querySelector(".navbar");
  const articles = [...document.querySelectorAll("section")];
  
  const DOWN = "DOWN";
  const UP = "UP";
  
  const options = {
  
    //root: document.body,
    rootMargin: `${pageHeader.offsetHeight * -1}px`,
    threshold: 0
  };
  
  let prevScrollTop = 0;
  
  function calcScrollDirection() {
    const { scrollTop } = document.documentElement;
    const direction = prevScrollTop < scrollTop ? DOWN : UP;
    prevScrollTop = scrollTop;
  
    return direction;
  }
  
  function getNextArticle(entry) {
    const index = articles.findIndex((article) => article == entry.target);
  
    return articles[index + 1];
  }
  
  function shouldUpdate(entry, direction) {
    return (
      (direction === DOWN && !entry.isIntersecting) ||
      (direction === UP && entry.isIntersecting)
    );
  }
  
  function onObserve(entries) {
    entries.forEach((entry) => {
      const direction = calcScrollDirection();
  
      if (shouldUpdate(entry, direction)) {
        const target = direction === DOWN ? getNextArticle(entry) : entry.target;
        const color = target.getAttribute("data-color");
        const colorNav = target.getAttribute("data-nav");
        pageHeader.setAttribute("style", `background-color: ${color};`);
        pageHeader.setAttribute("data-nav", colorNav);  
       
      }
    });
  }
  
  const observer = new IntersectionObserver(onObserve, options);
  
  articles.forEach((article) => {
    observer.observe(article);
  });
  
  
  if (document.querySelector("section") != null) {
  const coptions = {
    root: null, // Use the viewport as the root
    //rootMargin: '-200px 0px 0px 0px', // No margin around the root
    //threshold: 0 // Trigger when the top of the element reaches the top of the page
    };
  
    let revealCallback = (entries) => {
      entries.forEach((entry) => {
        let container = entry.target;
        if (entry.isIntersecting) {
          container.classList.add("fadeInUp");
          //revealObserver.unobserve("section");
          return;
        } 
    
  
      });
    };
  
    let revealObserver = new IntersectionObserver(revealCallback, coptions);
  
    document.querySelectorAll(".animated").forEach((reveal) => {
      revealObserver.observe(reveal);
    });
  }
  
  jQuery.imageMapProInitialized = function ( imageMapName ) {
      if ( imageMapName == 'Lifestyle' ) {
    
     const selectElement = document.querySelector('.imp-ui-layers-select');
      const listContentDiv = document.querySelector('.list-content');
      const ulElement = document.createElement('div');
      ulElement.classList.add('map-nav');
      selectElement.querySelectorAll('option').forEach((option, index) => {
          const liElement = document.createElement('div');
          liElement.classList.add('map-nav-item');
          liElement.textContent = option.textContent;
          liElement.setAttribute('data-id', option.textContent.replace(/\s/g, '')); 
          ulElement.appendChild(liElement);
      });
      listContentDiv.appendChild(ulElement);
      }
     
      const selectElement = document.querySelector('.imp-ui-layers-select');
      const options = selectElement.querySelectorAll('option');
      options.forEach((option, index) => {
              option.setAttribute('data-id', option.textContent.replace(/\s/g, ''));
      });
      var mapNavItems = document.querySelectorAll('.map-nav .map-nav-item');

      // Function to handle the click event
      function handleClick(e) {
          e.preventDefault();
          
          // Remove the active class from the currently active element
          document.querySelector('.map-nav .map-nav-item.active').classList.remove('active');
          
          // Add the active class to the clicked element
          this.classList.add('active');
          
          // Your existing code for handling the click event
          var dataFloorTitle = this.getAttribute('data-id');
          var tempFloor = document.querySelector('.imp-ui-layers-select option[data-id="' + dataFloorTitle + '"]').value;
          var selectElement = document.querySelector('.imp-ui-layers-select');
          selectElement.value = tempFloor;
          ImageMapPro.changeArtboard('Lifestyle', dataFloorTitle.replace(/\//g, ' / '));
      }
      
      // Add the click event listener to each map navigation item
      mapNavItems.forEach(function(element) {
          element.addEventListener('click', handleClick);
      });
      
      // Initially add the active class to the first element
      if (mapNavItems.length > 0) {
          mapNavItems[0].classList.add('active');
      } 
    };

